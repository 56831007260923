import { createApp as createClientApp } from 'vue'

import A11yDialog from 'vue-a11y-dialog'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createRouter } from './router'
import VueroApp from './VueroApp.vue'
import { plugin as FormKitPlugin, defaultConfig as FormKitConfig } from '@formkit/vue'
import './styles'

import { createApi } from '/@src/composable/useApi'
// Imports generated `@font-rules` CSS generated by `vite-plugin-fonts`.
// https://github.com/stafyniaksacha/vite-plugin-fonts#import-generated-font-rules-css
import 'virtual:fonts.css'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { setLocale as setValidationLocales, addMethod, number, NumberSchema } from 'yup'
import cloneDeep from 'lodash.clonedeep'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
// @ts-ignore
import vClickOutside from 'click-outside-vue3'

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/Bratislava")

const plugins = import.meta.glob('./plugins/*.ts')

export type VueroAppContext = Awaited<ReturnType<typeof createApp>>
export type VueroPlugin = (vuero: VueroAppContext) => void | Promise<void>

const FALLBACK_LANG = 'sk'
const fixedLocaleContent = document
  .getElementsByName('PORTAL_FIXED_LOCALE')[0]
  .getAttribute('content')
export const fixedLocale = !fixedLocaleContent
  ? undefined
  : /^[a-zA-Z]{1,3}$/.test(fixedLocaleContent) // regex test for dev server where we don't have the env variable, so it sets to sk
  ? fixedLocaleContent
  : FALLBACK_LANG

// this is a helper function to define plugins with autocompletion
export function definePlugin(plugin: VueroPlugin) {
  return plugin
}

// defineRule('email', (value: any) => email(value) || 'E-mail nemá správny formát')
// defineRule('numeric', (value: any) => numeric(value) || 'Údaj musí byť číslo')
// defineRule(
//   'required',
//   (value: any) =>
//     required(value) || (Array.isArray(value) && value.length > 0) || 'Údaj je povinný'
// )
// defineRule(
//   'min',
//   (value: any, length: number) =>
//     min(value, { length }) || `Údaj musí mať najmenej ${length} znakov`
// )
// defineRule(
//   'max',
//   (value: any, length: number) =>
//     max(value, { length }) || `Údaj môže mať nanajvýš ${length} znakov`
// )
// defineRule('date', (value: any) => {
//   return (
//     dayjs(`${value.year}-${value.month}-${value.day}`, 'YYYY-M-D', true).isValid() ||
//     `Dátum má nesprávny formát`
//   )
// })

setValidationLocales({
  number: {
    positive: 'Musíte zadať pozitívne číslo',
    min: ({ min }) => `Číslo musí mať minimálne ${min} znakov`,
    max: ({ max }) => `Číslo musí mať maximálne ${max} znakov`,
  },
  mixed: {
    required: ({ label, path }) => {
      // check all fieldnames and then uncomment
      // return ({ key: 'errors.required', values: { name: label ?? path }})
      return 'Údaj je povinný'
    },
  },
  string: {
    email: 'E-mail nemá správny formát',
    length: ({ length }) => `Hodnota musí mať ${length} znakov`,
    min: ({ min }) => `Hodnota musí mať minimálne ${min} znakov`,
    max: ({ max }) => `Hodnota musí mať maximálne ${max} znakov`,
  },
})

const YEAR_FORMAT = 'YYYY'
addMethod<NumberSchema>(
  number,
  'year',
  function year(errorMessage = 'Rok nemá správny formát') {
    return this.test(`test-year`, errorMessage, function (value) {
      const { path, createError } = this
      const stringValue = String(value)
      const date = dayjs(stringValue, YEAR_FORMAT, true)
      const before = dayjs(stringValue, YEAR_FORMAT).isBefore(dayjs('2999', YEAR_FORMAT))
      const after = dayjs(stringValue, YEAR_FORMAT).isAfter(dayjs('1900', YEAR_FORMAT))
      return (
        (date.isValid() && before && after) ||
        createError({ path, message: errorMessage })
      )
    })
  }
)

export async function createApp() {
  const app = createClientApp(VueroApp)
  const router = createRouter()
  const api = createApi()
  const amsEnv = document
    .getElementsByName('AMS_ENV')[0]
    .getAttribute('content')
    ?.toString()
    .replace(/\s/g, '')
  const sentryDsn = document
    .getElementsByName('SENTRY_DSN')[0]
    .getAttribute('content')
    ?.toString()
    .replace(/\s/g, '')
  const amsRevision = document
    .getElementsByName('AMS_REVISION')[0]
    .getAttribute('content')
    ?.toString()
    .replace(/\s/g, '')
  const amsDeploy = document
    .getElementsByName('AMS_DEPLOY')[0]
    .getAttribute('content')
    ?.toString()
    .replace(/\s/g, '')
  const enviroments = [] //['be', 'fe', 'sit', 'uat', 'fot']

  const head = createHead()
  app.use(head)

  app.use(A11yDialog)
  app.use(FormKitPlugin, FormKitConfig)

  const pinia = createPinia()
  pinia.use(({ store }) => {
    const initialState = cloneDeep(store.$state)
    store.$reset = () => store.$patch(cloneDeep(initialState))
  })
  app.use(pinia)

  if (enviroments.includes(<string>amsEnv)) {
    Sentry.init({
      app,
      dsn: sentryDsn,
      environment: amsEnv,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })
    Sentry.setTags({ revision: amsRevision, deploy: amsDeploy })
  }
  const vuero = {
    app,
    api,
    router,
    head,
    pinia,
  }

  app.provide('vuero', vuero)

  for (const path in plugins) {
    try {
      const { default: plugin } = await plugins[path]()
      await plugin(vuero)
    } catch (error) {
      console.error(`Error while loading plugin "${path}".`)
      console.error(error)
    }
  }

  // use router after plugin registration, so we can register navigation guards
  app.use(vuero.router)

  app.use(vClickOutside)

  return vuero
}
