<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

export interface IDSKSkipLinkProps {
  contentId: string
}

const { t } = useI18n()

const props = defineProps<IDSKSkipLinkProps>()
</script>

<template>
  <a :href="`#${props.contentId}`" class="govuk-skip-link">{{ t('text') }}</a>
</template>

<style scoped>

</style>

<i18n lang="yaml">
sk:
  text: 'Preskočiť na hlavný obsah'
en:
  text: 'Skip to main content'
</i18n>
