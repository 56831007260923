import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useStorage } from '@vueuse/core'
import {
  OsobaPortalZoznamResponse,
  ProfilPortalResponse,
  Subjekt,
} from '../client-portal'

export type SearchValues = {
  meno: string
  priezvisko: string
  rodneCislo?: string
  datumNarodenia?: Date
}

export const useUserSession = defineStore('userSession', () => {
  // token will be synced with local storage
  // @see https://vueuse.org/core/usestorage/
  const token = useStorage('access_token', '')

  const user = ref<Partial<ProfilPortalResponse>>()
  const loading = ref(true)
  const subjekt = ref<Partial<Subjekt>>()
  const addOsobaInfo = useStorage('addOsobaInfo', {})
  const searchValues = ref<SearchValues>()
  const redirect = useStorage<string>('redirect', '')

  const isLoggedIn = computed(() => !!token.value)

  function setUser(newUser: Partial<ProfilPortalResponse>) {
    user.value = newUser
  }

  function setSubjekt(newSubjekt: Partial<Subjekt>) {
    subjekt.value = newSubjekt
  }

  function setAddOsobaInfo(osoba: OsobaPortalZoznamResponse) {
    addOsobaInfo.value = osoba
  }
  function setSearchValues(newSearchValues: SearchValues) {
    searchValues.value = newSearchValues
  }

  function setToken(newToken: string) {
    token.value = newToken
  }

  function setLoading(newLoading: boolean) {
    loading.value = newLoading
  }

  function setRedirect(newRedirect: string | undefined) {
    redirect.value = newRedirect
  }

  async function logoutUser() {
    token.value = undefined
    user.value = undefined
    addOsobaInfo.value = null
    redirect.value = ''
  }

  return {
    user,
    token,
    subjekt,
    addOsobaInfo,
    searchValues,
    isLoggedIn,
    loading,
    redirect,
    logoutUser,
    setUser,
    setSubjekt,
    setAddOsobaInfo,
    setSearchValues,
    setToken,
    setLoading,
    setRedirect,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
