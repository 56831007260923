<script setup lang="ts">
import * as globalRules from '@vee-validate/rules'
import { configure, defineRule } from 'vee-validate'
import sk from '@vee-validate/i18n/dist/locale/sk.json'
import { localize, setLocale } from '@vee-validate/i18n'
// This is the global app setup function

configure({
  generateMessage: localize({
    sk,
  }),
})
setLocale('sk')

localize('sk', {
  messages: {
    rodneCislo: "Rodné číslo zadávajte v tvare bez '/'",
    telefonneCislo:
      'Nesprávne telefónne číslo (zadať predčíslie štátu.  Napr. Slovensko  +421…)',
    orientacneCislo: 'Orientačné číslo nemôže obsahovať špeciálne znaky.',
    min: 'Položka musí obsahovať minimálne 0:{length} znakov',
    required: 'Položka je povinná',
  },
})

defineRule('email', globalRules.email)
defineRule('max', globalRules.max)
defineRule('min', globalRules.min)
defineRule('telefonneCislo', globalRules.regex)
defineRule('regex', globalRules.regex)
defineRule('supisneCislo', globalRules.regex)
defineRule('orientacneCislo', globalRules.regex)
defineRule('required', globalRules.required)

const MAIN_CONTENT_ID = 'main-content'
</script>
<template>
  <IDSKSkipLink :content-id="MAIN_CONTENT_ID" />

  <RouterView v-slot="{ Component }">
    <Transition name="fade-slow" mode="out-in">
      <div style="min-height: 100vh; display: flex; flex-direction: column;">
        <component :is="Component" style="min-height: 100vh" />
      </div>
    </Transition>
  </RouterView>
</template>
